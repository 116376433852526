$(function () {

    // Don't show cookie consent form on cookie-information page
    if (window.location.href.includes(cookie_link)) return;

    if (Cookies.get("cookie-consent") === undefined && Cookies.get("temp-cookie-consent") === undefined)  {

        let div = $("<div>").addClass("cookie-consent");
        let container = $("<div>").addClass("content-block").appendTo(div);
        let row = $("<div>").addClass("cookie-consent--body").appendTo(container);

        $(`${LocalizationRes.global_AgilaTopLeftLogoImgTag}`).appendTo(row);
        $("<br />").appendTo(row);
        $("<h3>").text(LocalizationRes.global_CoookieHeading).appendTo(row);

        let text = $("<div style='text-align: justify'>").addClass("text").appendTo(row);
        let p = $("<p>").appendTo(text);
        let span = $("<span>").text(LocalizationRes.global_CoookieImplemented + " ").appendTo(p);

        $("<a>").attr({ "href": cookie_link }).text(LocalizationRes.ReadMore).appendTo(p);

        var actions = $("<div style='margin-top: 2rem'>").addClass("cookie-consent--actions").appendTo(row);

        $("<button>").addClass("btn btn-default").text(LocalizationRes.global_CoookieReject).on("click", function (e) {
            e.preventDefault();
            Cookies.set('temp-cookie-consent', 'reject', { expires: 0.5 });
            div.fadeOut(300, function() {
                div.remove();
                $("body").removeClass("has-cookie-consent");
            })
            window.dispatchEvent(new Event('cookie-consent-clicked'));

        }).appendTo(actions);

        $("<button>").addClass("btn btn-primary").text(LocalizationRes.global_CoookieAllow).on("click", function (e) {
            e.preventDefault();
            Cookies.set('cookie-consent', 'agila', { expires: 365 });

            gtag('consent', 'update', {
                'ad_storage': 'granted',
                'analytics_storage': 'granted',
                'functionality_storage': 'granted',
                'personalization_storage': 'granted',
                'security_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                wait_for_update: 1500,
            });

            dataLayer.push({ 'event': 'consent_updated' });
            window.dispatchEvent(new Event('cookie-consent-updated'));

            div.fadeOut(300, function () {
                div.remove();
                $("body").removeClass("has-cookie-consent");
            })

            window.dispatchEvent(new Event('cookie-consent-clicked'));
        }).appendTo(actions);

        $("div.ontop-top").append(div);

        $("body").addClass("has-cookie-consent");
    }
});

